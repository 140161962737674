import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DonationFormData } from './donation.type';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  TextField,
} from '@mui/material';
import {
  AMOUNT_OPTIONS,
  CHECKOUT_METHODS,
  FORM_STATES,
  PAYMENT_METHODS,
} from './utils';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { styles } from './styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  a11yProps,
  CustomTabPanel,
  ErrorDialog,
  SuccessDialog,
} from './helpers';
import { createPaymentAgreement } from '../../mutations';
import PayToIcon from '../../assets/images/pay-to.svg';
import { CustomColors } from '../../theme';
import MaskedInput from 'react-text-mask';

const Donation = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(FORM_STATES.FORM);
  const [selectedBtn, setSelectedBtn] = useState<number | null>(null);
  const [tab, setTab] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
    PAYMENT_METHODS.PAYTO
  );

  const handleRadioChange = (value: string) => {
    setSelectedPaymentMethod(value);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
  } = useForm<DonationFormData>();

  let amount = watch('amount');

  const setAmountValue = (amount: number) => {
    setValue('amount', amount);
  };

  const handleContinue = () => {
    if (selectedPaymentMethod === PAYMENT_METHODS.PAYID) {
      setState(FORM_STATES.SUCCESS);
      return;
    }
    if (amount === null || !amount || amount <= 0 || amount >= 1000) {
      setError('amount', {
        type: 'manual',
        message: 'Amount must be between $1 and $999',
      });
    } else {
      setState(FORM_STATES.CHECKOUT);
    }
  };

  const onSubmit: SubmitHandler<DonationFormData> = async (data) => {
    try {
      setLoading(true);

      if (Object.keys(errors).length === 0) {
        let checkoutMethod = CHECKOUT_METHODS.PAYID_PHONE;

        if (tab === 1) {
          checkoutMethod = CHECKOUT_METHODS.PAYID_EMAIL;
        } else if (tab === 2) {
          checkoutMethod = CHECKOUT_METHODS.ACCOUNT;
        }
        const response = await createPaymentAgreement(
          data.amount.toString(),
          checkoutMethod,
          {
            payIdPhone: data.payIdPhone,
            payIdEmail: data.payIdEmail,
            account: data.account,
            bsb: data.bsb,
          }
        );
        console.log(response);
        setState(FORM_STATES.SUCCESS);
      }
      setLoading(false);
    } catch (error) {
      setState(FORM_STATES.ERROR);
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {state === FORM_STATES.FORM && (
          <>
            <h3 style={styles.heading}>Donation Amount</h3>
            <ButtonGroup variant="contained" aria-label="Amount button group">
              {AMOUNT_OPTIONS.map((amount, index) => (
                <Button
                  size="large"
                  key={index}
                  color={selectedBtn === index ? 'primary' : 'info'}
                  onClick={() => {
                    setAmountValue(amount);
                    setSelectedBtn(index);
                  }}
                >
                  $ {amount}
                </Button>
              ))}
            </ButtonGroup>
            <Grid item md={12}>
              <FormControl fullWidth margin="normal">
                <Controller
                  name="amount"
                  control={control}
                  rules={{
                    required: 'Amount is required',
                    min: {
                      value: 1,
                      message: 'Amount must be between $1 and $999',
                    },
                  }}
                  defaultValue={0}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        label="Amount"
                        variant="outlined"
                        type="number"
                        error={!!errors.amount}
                      />
                      {errors.amount && (
                        <FormHelperText error>
                          {errors.amount.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <div
                style={{
                  borderRadius: 5,
                  background:
                    PAYMENT_METHODS.PAYTO === selectedPaymentMethod
                      ? CustomColors.lilac
                      : 'transparent',
                }}
                onClick={() => handleRadioChange(PAYMENT_METHODS.PAYTO)}
              >
                <div style={styles.radioContainer}>
                  <div style={styles.radioLabelContainer}>
                    <img
                      src={PayToIcon}
                      style={styles.radioIcon}
                      alt={PAYMENT_METHODS.PAYTO}
                    />
                    <div style={styles.radioLabelTextContainer}>
                      <h4 style={styles.radioLabel}>{PAYMENT_METHODS.PAYTO}</h4>
                    </div>
                  </div>
                  <FormControlLabel
                    label=""
                    control={<Radio />}
                    checked={selectedPaymentMethod === PAYMENT_METHODS.PAYTO}
                    onChange={() => handleRadioChange(PAYMENT_METHODS.PAYTO)}
                    value={PAYMENT_METHODS.PAYTO}
                  />
                </div>

                <div style={styles.helperText}>
                  <p>Instant and secure. Authorise using your bank.</p>
                </div>
              </div>
              {/* <div
                style={{
                  borderRadius: 5,
                  background:
                    PAYMENT_METHODS.PAYID === selectedPaymentMethod
                      ? CustomColors.lilac
                      : 'transparent',
                }}
                onClick={() => handleRadioChange(PAYMENT_METHODS.PAYID)}
              >
                <div style={styles.radioContainer}>
                  <div style={styles.radioLabelContainer}>
                    <img
                      src={PayIdIcon}
                      style={styles.radioIcon}
                      alt={PAYMENT_METHODS.PAYID}
                    />
                    <div style={styles.radioLabelTextContainer}>
                      <h4 style={styles.radioLabel}>{PAYMENT_METHODS.PAYID}</h4>
                    </div>
                  </div>
                  <FormControlLabel
                    label=""
                    control={<Radio />}
                    checked={selectedPaymentMethod === PAYMENT_METHODS.PAYID}
                    onChange={() => handleRadioChange(PAYMENT_METHODS.PAYID)}
                    value={PAYMENT_METHODS.PAYID}
                  />
                </div>
                <div style={styles.helperText}>
                  <p>
                    Instant, secure payments from your bank to monoova Can take
                    up to 24 hours.
                  </p>
                  {selectedPaymentMethod === PAYMENT_METHODS.PAYID && (
                    <>
                      <p>PayID depositaud@moonova.com</p>
                      <p>Reference 100025559800</p>
                      <p>Once complete, Click “I’ve deposited funds” button</p>
                    </>
                  )}
                </div>
              </div> */}
            </Grid>
            <div style={styles.btnContainer}>
              <Button
                variant="contained"
                color="primary"
                style={styles.btn}
                size="large"
                type="button"
                disabled={loading || !selectedPaymentMethod}
                onClick={() => handleContinue()}
              >
                Continue
              </Button>
            </div>
          </>
        )}

        {state === FORM_STATES.CHECKOUT && (
          <div>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  aria-label="tabs example"
                >
                  <Tab label={CHECKOUT_METHODS.PAYID_PHONE} {...a11yProps(2)} />
                  <Tab label={CHECKOUT_METHODS.PAYID_EMAIL} {...a11yProps(1)} />
                  <Tab label={CHECKOUT_METHODS.ACCOUNT} {...a11yProps(0)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={tab} index={0}>
                <FormControl fullWidth margin="normal">
                  <Controller
                    name="payIdPhone"
                    control={control}
                    rules={{
                      required: 'Phone is required',
                    }}
                    defaultValue="+61-"
                    render={({ field }) => (
                      <>
                        <MaskedInput
                          {...field}
                          guide={false}
                          mask={[
                            '+',
                            '6',
                            '1',
                            '-',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          render={(ref, props) => (
                            <TextField
                              {...props}
                              inputRef={ref}
                              label="Phone"
                              variant="outlined"
                              type="text"
                              error={!!errors.payIdPhone}
                            />
                          )}
                        />
                        {errors.payIdPhone && (
                          <FormHelperText error>
                            {errors.payIdPhone.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={1}>
                <FormControl fullWidth margin="normal">
                  <Controller
                    name="payIdEmail"
                    control={control}
                    rules={{
                      required: 'Email is required',
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          label="Email"
                          variant="outlined"
                          type="email"
                          error={!!errors.payIdEmail}
                        />
                        {errors.payIdEmail && (
                          <FormHelperText error>
                            {errors.payIdEmail.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={2}>
                <FormControl fullWidth margin="normal">
                  <Controller
                    name="account"
                    control={control}
                    rules={{ required: 'Account Number is required' }}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <MaskedInput
                          {...field}
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          guide={false}
                          render={(ref, props) => (
                            <TextField
                              {...props}
                              inputRef={ref}
                              label="Account Number"
                              variant="outlined"
                              type="text"
                              error={!!errors.account}
                            />
                          )}
                        />
                        {errors.account && (
                          <FormHelperText error>
                            {errors.account.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <Controller
                    name="bsb"
                    control={control}
                    rules={{ required: 'BSB is required' }}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <MaskedInput
                          {...field}
                          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                          guide={false}
                          render={(ref, props) => (
                            <TextField
                              {...props}
                              inputRef={ref}
                              label="BSB"
                              variant="outlined"
                              type="text"
                              error={!!errors.bsb}
                            />
                          )}
                        />
                        {errors.bsb && (
                          <FormHelperText error>
                            {errors.bsb.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </CustomTabPanel>
            </Box>
            <div style={styles.btnContainer}>
              <Button
                variant="contained"
                color="primary"
                style={styles.btn}
                size="large"
                type="submit"
                disabled={loading}
              >
                Continue
              </Button>
            </div>
          </div>
        )}

        {state === FORM_STATES.SUCCESS && (
          <div>
            <SuccessDialog />
          </div>
        )}

        {state === FORM_STATES.ERROR && (
          <div>
            <ErrorDialog />
          </div>
        )}
      </form>
    </div>
  );
};

export default Donation;
